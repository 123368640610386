/*
    Design notes:

    Support types for TableColumns<T> providing
    - search cache generation
    - column size/type handling

*/
export const columnType: Record<ColumnTypeLabel, ColumnType> = {
    ct_string: 'string',
    ct_link: 'link',
    ct_image: 'image',
    ct_avatar: 'avatar'
};

export type ColumnType = 'string' | 'link' | 'image' | 'avatar';
export type ColumnTypeLabel = 'ct_string' | 'ct_link' | 'ct_image' | 'ct_avatar';

export enum ColumnSizeType {
    cst_None,
    cst_Fixed,
    cst_Variable
}
export type ColumnDefs = columnDef[];

export interface columnDef {
    name: string;
    alias: string;
    visible: boolean;
    type: string;
    order: number;

    // update for optimal styling
    widthType: ColumnSizeType;
    width: number;

    derived?<Type>(data: Type): string; // function to return eg. an img source of text source
}
export class Columns {
    // column def
    public columnMap = new Map<string, columnDef>();
    public columnMapVis = new Map<string, columnDef>();
    // optimisation
    public defs: ColumnDefs = new Array<columnDef>();
    public totalFixedWidth = 0;
    public totalFixedCount = 0;
    public totalVariableWidth = 0;
    public totalVariableCount = 0;

    reset() {
        this.columnMap.clear();
        this.columnMapVis.clear();
    }

    add(name: string, alias: string, visible: boolean, type: ColumnType, order: number, widthType: ColumnSizeType, width: number, derived?: (data: any) => string) {
        this.columnMap.set(name, { name, alias, visible, type, order, widthType, width, derived });
        if (visible) {
            this.columnMapVis.set(name, { name, alias, visible, type, order, widthType, width });
        }
    }
    columns(): ColumnDefs {
        return Array.from(this.columnMap.values()).sort((n1, n2) => {
            if (n1.order > n2.order) {
                return 1;
            }
            if (n1.order < n2.order) {
                return -1;
            }
            return 0;
        });
    }
    calculateColumns() {
        /*
            - sort columns based on defined ordering
        */
        this.defs = Array.from(this.columnMap.values()).sort((n1, n2) => {
            if (n1.order > n2.order) {
                return 1;
            }
            if (n1.order < n2.order) {
                return -1;
            }
            return 0;
        });
        /*
            - calculate total fixed & variable sizes for all specified columns
            - scale all variable sizes to fit in 100%
            - setup calc method to map variable columns based on reduced size due to fixed columms eg. calc((100vw-64px) * 0.2)
        */
        this.totalFixedWidth = 0;
        this.totalFixedCount = 0;
        this.totalVariableWidth = 0;
        this.totalVariableCount = 0;
        this.defs.forEach((col) => {
            switch (col.widthType) {
                case ColumnSizeType.cst_Fixed: {
                    this.totalFixedWidth += col.width;
                    this.totalFixedCount++;
                    break;
                }
                case ColumnSizeType.cst_Variable: {
                    this.totalVariableWidth += col.width;
                    this.totalVariableCount++;
                    break;
                }
            }
        });
        // rescale variable columns
        //let variableRescale = 100/totalVariableWidth;
    }
    columnWidth(colName: string): any {
        const cd = this.columnMap.get(colName);
        if (cd) {
            switch (cd.widthType) {
                case ColumnSizeType.cst_Fixed: {
                    return { p: 1, width: cd.width.toString() + 'px' };
                }
                case ColumnSizeType.cst_Variable: {
                    return { p: 1, maxWidth: 'calc((max(200vw,1000px) - ' + this.totalFixedWidth + 'px) * ' + (cd.width / 100).toString() + ')' };
                }
                default:
                    return {};
            }
        }
        /*
        return width for fixed
               max-width for variable
        */
    }
    generateSearchCacheGeneric<Type>(data: Type[] | null, IDfield: string): Map<string, string> {
        // pre-process data to create optimised search
        if (data) {
            const sc = new Map<string, string>();
            console.log('prepareSearchCache');
            const visCols = Array.from(this.columnMapVis.keys());
            data.map((m: Type) => {
                let mds = '';
                visCols.forEach((colName: string) => {
                    if ((m as any)[colName]) {
                        mds += (m as any)[colName]?.toLowerCase() + ' ';
                    }
                });
                sc.set((m as any)[IDfield], mds);
                return sc;
            });
            return sc;
        } else {
            return new Map<string, string>();
        }
    }
}

export {};
