import React, { ReactElement, useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, Typography, Popover } from '@mui/material';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
// icons
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import PersonIcon from '@mui/icons-material/Person';
import InfoIcon from '@mui/icons-material/Info';
import GroupsIcon from '@mui/icons-material/Groups';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import API & used  types
import { API } from '../api/apiInterface';
import { AuthenticationMember } from '../api/apiTypes';
// import styles
import { Search, SearchIconWrapper, StyledInputBase, StyledToolbar, StyledTooltip } from '../customisation/theme/styles';
// import global contexts
import { useAuthContext } from '../contexts/AuthContext';
import { useOrganisationContext } from '../contexts/OrganisationContext';
import { LayoutMode, useLayoutContext } from '../contexts/LayoutContext';
import { useSearchQueryContext } from '../contexts/SearchQueryContext';
import { useSearchActiveContext } from '../contexts/SearchActiveContext';
import OrganisationPage from '../pages/Organisation';
import OrganisationsPage from '../pages/Organisations';
import Grid from '@mui/material/Grid';
import { useVirtualHistory } from '../classes/virtualHistory';

const Navbar = (): ReactElement => {
    // use global contects
    const { layout } = useLayoutContext();
    const { auth } = useAuthContext();
    const { organisation } = useOrganisationContext();
    const { searchActive } = useSearchActiveContext();
    const { searchQuery, setSearchQuery } = useSearchQueryContext(); // &filter=

    const [activeProfileOrg, setActiveProfileOrg] = useState<AuthenticationMember | null>(null);
    // User Login/Profiles menu
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    // copy URL
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    // search operation info
    const [anchorElSI, setAnchorElSI] = useState<HTMLButtonElement | null>(null);

    const [search] = useSearchParams();
    const location = useLocation();

    const lastPartOfUrl = location.pathname.split('/')[location.pathname.split('/').length - 1];
    const isMemberProfileView = !!Number(lastPartOfUrl);
    const isMembersListView = !isMemberProfileView;

    // virtual navigation hooks
    const { vh_back, vh_goto } = useVirtualHistory();

    const handleFilter = (event: { target: { value: string } }) => {
        const ss = event.target.value;
        setSearchQuery(ss);
    };

    // run on location change
    // send URL to iframe host when in embedded mode
    useEffect(() => {
        if (layout === LayoutMode.lm_embedded) {
            const lm = 'URL:' + location.pathname + location.search;

            window.parent.postMessage(lm, '*');
        }
    }, [location, layout]);

    // run on auth change
    // send Login state  to iframe host when in embedded mode
    useEffect(() => {
        if (layout === LayoutMode.lm_embedded) {
            const lm = 'Auth:' + auth?.authenticated;
            window.parent.postMessage(lm, '*');
        }
    }, [auth, layout]);

    // run on auth / organisation change
    useEffect(() => {
        if (auth && auth.authenticated === 1 && organisation) {
            for (const o of auth.organisationsWithActiveMembership) {
                if (organisation?.Slug === o.Slug) {
                    setActiveProfileOrg(o);
                    break;
                }
            }
        } else {
            setActiveProfileOrg(null);
        }
    }, [auth, organisation]);

    const handleOrganisationOpen = () => {
        if (organisation) {
            vh_goto(organisation?.Slug);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openCopyURL = Boolean(anchorEl);
    const openCopyURLid = openCopyURL ? 'simple-popover' : undefined;

    const handleOpenSI = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElSI(event.currentTarget);
    };
    const handleCloseSI = () => {
        setAnchorElSI(null);
    };
    const openSearchInfo = Boolean(anchorElSI);
    const openSearchInfoid = openSearchInfo ? 'simple-popover' : undefined;

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = (setting: string) => {
        // hide menu
        setAnchorElUser(null);
        // process option
        if (typeof setting === 'string') {
            if (setting.indexOf(':') === -1) {
                switch (setting) {
                    case 'Login':
                        // redirect to api login
                        window.location.href = API.apiBase.requestContext.urlLogin + '?' + API.apiBase.requestContext.loginParam + '=' + window.location.href;
                        // after login we are returned to this URL & loginState is set
                        break;
                    case 'Profile':
                        // goto current organisations linked membership
                        if (activeProfileOrg) {
                            // eg. https://rf.nomadit.co.uk/easa/7987?filter=torre
                            const pURL = activeProfileOrg.Slug + '/' + activeProfileOrg.MembershipID;
                            //navigate(pURL);
                            vh_goto(pURL);
                        }
                        break;
                    case 'Logout':
                        // redirect to api logout
                        window.location.href = API.apiBase.requestContext.urlLogout + '?' + API.apiBase.requestContext.loginParam + '=' + window.location.href;
                        break;
                    default:
                        //
                        break;
                }
            } else {
                // find matching authenticationMember record & retrieve MembershipID to use
                const orgSlug = setting.split(':').pop();
                if (auth && auth.authenticated === 1) {
                    for (const o of auth.organisationsWithActiveMembership) {
                        if (o.Slug === orgSlug) {
                            // navigate to profile / member view page eg. https://rf.nomadit.co.uk/easa/7987?filter=torre
                            const pURL = orgSlug + '/' + o.MembershipID;
                            //navigate(pURL);
                            vh_goto(pURL);
                            break;
                        }
                    }
                }
            }
        }
    };

    const MenuVisisble = (): boolean => {
        return (
            (!activeProfileOrg && auth?.authenticated === 1 && auth?.organisationsWithActiveMembership.length > 0) ||
            activeProfileOrg !== null ||
            (auth?.authenticated === 0 && (layout !== LayoutMode.lm_embedded || DBG_ShowLogin)) ||
            (auth?.authenticated === 1 && (layout !== LayoutMode.lm_embedded || DBG_ShowLogin))
        );
    };

    // LOGIN DEBUG MODE ON/OFF
    const DBG_ShowLogin = false;

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    backgroundColor: 'secondary.main',
                    position: 'sticky',
                    top: '0px',
                    zIndex: '1'
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={searchActive ? 12 : 10} sm={searchActive ? 6 : 10}>
                        <StyledToolbar variant="dense">
                            {isMemberProfileView ? (
                                <IconButton onClick={handleOrganisationOpen} sx={{ p: 1 }}>
                                    <ArrowBackIcon />
                                </IconButton>
                            ) : null}
                            <>
                                <Typography
                                    variant="h6"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' }
                                    }}
                                >
                                    {organisation ? `${organisation.Title} Members directory` : 'Organisations'}
                                </Typography>
                                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    {organisation ? `${organisation.Title} Members directory` : 'Organisations'}
                                </Typography>
                            </>
                        </StyledToolbar>
                    </Grid>
                    <Grid item xs={searchActive ? 12 : 2} sm={searchActive ? 6 : 2}>
                        <StyledToolbar variant="dense" sx={{ flexDirection: 'row-reverse' }}>
                            {MenuVisisble() && (
                                <>
                                    <Tooltip title="Edit your profile">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0.8 }}>
                                            <PersonIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {auth?.authenticated === 0 && (layout !== LayoutMode.lm_embedded || DBG_ShowLogin) && (
                                            <MenuItem key={'Login'} onClick={(any) => handleCloseUserMenu('Login')}>
                                                <Typography textAlign="center">Login</Typography>
                                            </MenuItem>
                                        )}

                                        {activeProfileOrg && (
                                            <MenuItem key={'Profile active'} onClick={(any) => handleCloseUserMenu('Profile')}>
                                                <Typography textAlign="center">Profile</Typography>
                                            </MenuItem>
                                        )}

                                        {!activeProfileOrg &&
                                            auth?.authenticated === 1 &&
                                            auth?.organisationsWithActiveMembership.map((om) => (
                                                <MenuItem key={'Profile:' + om.Slug} onClick={(any) => handleCloseUserMenu('Profile:' + om.Slug)}>
                                                    <Typography textAlign="center">Profile: {om.ShortName}</Typography>
                                                </MenuItem>
                                            ))}

                                        {auth?.authenticated === 1 && (layout !== LayoutMode.lm_embedded || DBG_ShowLogin) && (
                                            <MenuItem key={'Logout'} onClick={(any) => handleCloseUserMenu('Logout')}>
                                                <Typography textAlign="center">Logout</Typography>
                                            </MenuItem>
                                        )}
                                    </Menu>
                                </>
                            )}

                            <Popover
                                id={openCopyURLid}
                                open={openCopyURL}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                }}
                            >
                                <Typography sx={{ p: 2 }}>URL copied to clipboard</Typography>
                            </Popover>
                            {isMembersListView && (
                                <>
                                    <StyledTooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>{`Separate search terms using a comma.
                                                Edit your entry: log in -> account & memberships.`}</div>
                                        }
                                    >
                                        <Search>
                                            <SearchIconWrapper>
                                                <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                                id="globalSearch"
                                                placeholder="Search…"
                                                inputProps={{ 'aria-label': 'search' }}
                                                value={searchQuery}
                                                onChange={handleFilter}
                                                spellCheck="false"
                                                autoComplete="off"
                                            />
                                        </Search>
                                    </StyledTooltip>
                                    <Tooltip
                                        title={
                                            <div style={{ whiteSpace: 'pre-line' }}>{`Separate search terms using a comma.
                                                Edit your entry: log in -> account & memberships.`}</div>
                                        }
                                    >
                                        <IconButton aria-describedby={openSearchInfoid} onClick={handleOpenSI} sx={{ p: 0.8 }}>
                                            <InfoIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        id={openSearchInfoid}
                                        open={openSearchInfo}
                                        anchorEl={anchorElSI}
                                        onClose={handleCloseSI}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left'
                                        }}
                                    >
                                        <Typography sx={{ p: 2, whiteSpace: 'pre-line' }}>
                                            {`Separate search terms using a comma.
                                              Edit your entry: log in -> account & memberships.`}
                                        </Typography>
                                    </Popover>
                                </>
                            )}
                        </StyledToolbar>
                    </Grid>
                </Grid>
            </Box>
            <Routes>
                <Route index element={<OrganisationsPage />} />
                <Route path=":orgSlug/*" element={<OrganisationPage />} />
            </Routes>
        </>
    );
};

export default Navbar;
