import { organisations } from '../api/apiTypes';
import { createContext, useContext } from 'react';

export type OrganisationsBaseType = {
    organisations: organisations | null;
    setOrganisations: (b: organisations | null) => void;
};

export const OrganisationsBase = createContext<OrganisationsBaseType>({
    organisations: null, // set a default value
    setOrganisations: (base: organisations | null) => undefined
});
export const useOrganisationsContext = () => useContext(OrganisationsBase);

/*
to use :
    
    import { useOrganisationsContext } from '../contexts/OrganisationsContext';

    const { organisations, setOrganisations } = useOrganisationsContext();

*/
