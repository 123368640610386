import React, { useEffect, useState, FormEvent, useContext, useRef } from 'react';

import { useTheme } from '@mui/material/styles';

import { API } from '../api/apiInterface';
import { Member } from '../api/apiTypes';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

import CloseIcon from '@mui/icons-material/Close';

// contact dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Alert, AlertColor, Avatar, CircularProgress, Collapse } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTimeout } from 'usehooks-ts';

export interface IEmailSendDialogProps {
    member: Member | null;
    open: boolean;
    handleClose: () => void;
}

const EmailSendDialog: React.FunctionComponent<IEmailSendDialogProps> = ({ member, open, handleClose }) => {
    console.log('EmailSendDialog');

    const recaptchaRef = React.createRef<ReCAPTCHA>();
    const [contactSendEnabled, setContactSendEnabled] = React.useState(false);
    const handleRecaptchaChange = (token: string | null) => {
        console.log(token);
        if (token) {
            setContactSendEnabled(true);
        } else {
            setContactSendEnabled(false);
        }
    };

    // from field
    const [from_fb_Open, setFrom_fb_Open] = React.useState(false);
    const [from_fb_Info, setFrom_fb_Info] = React.useState('');
    // subject field
    const [subject_fb_Open, setSubject_fb_Open] = React.useState(false);
    const [subject_fb_Info, setSubject_fb_Info] = React.useState('');
    // message (/text) field
    const [message_fb_Open, setMessage_fb_Open] = React.useState(false);
    const [message_fb_Info, setMessage_fb_Info] = React.useState('');

    // generic
    const [feedbackOpen, setFeedbackOpen] = React.useState(false);
    const [feedbackInfo, setFeedbackInfo] = React.useState('');
    const [feedbackState, setFeedbackState] = React.useState<AlertColor | undefined>(undefined);

    const handleSendClick = (event: React.FormEvent<HTMLFormElement>) => {
        console.log('Email form submit');
        event.preventDefault();

        /*
        debugNoSend: 1 optional
        dstMid: 7987 mid of member to be sent the message
        from: noone@nowhere.no
        subject :a subject
        text: multiline text message
        */

        if (member) {
            const target = event.target as typeof event.target & {
                fromEmail: { value: string };
                subject: { value: string };
                text: { value: string };
            };
            const formData = new FormData();

            // DEBUG MODE - DONT SEND EMAIL!
            formData.append('debugNoSend', '1');

            formData.append('dstMid', member.MembershipID);
            formData.append('from', target.fromEmail.value);
            formData.append('subject', target.subject.value);
            formData.append('text', target.text.value);
            formData.append('token', recaptchaRef.current?.getValue() || ''); // send empty string if token not defined

            console.log(formData.get('debugNoSend'));

            console.log(formData.get('dstMid'));
            console.log(formData.get('from'));
            console.log(formData.get('subject'));
            console.log(formData.get('text'));
            console.log(formData.get('token'));

            // send request via api
            API.sendEmail(formData).then((response) => {
                console.log(JSON.stringify(response));

                if (response.success) {
                    setContactSendEnabled(false); // disable send
                    setFeedbackState('success');
                    setFeedbackInfo('message sent');
                    setFeedbackOpen(true);

                    // pause for 2s allowing user to read message sent feedback
                    setTimeout(() => {
                        setFeedbackOpen(false);
                        setFrom_fb_Open(false);
                        setSubject_fb_Open(false);
                        setMessage_fb_Open(false);
                        setContactSendEnabled(true); // disable send
                        handleClose();
                    }, 2000);
                } else {
                    // show error somehow
                    setFeedbackState('error');
                    if ((response as any).errorDesc) {
                        console.log((response as any).errorDesc);
                        setFeedbackInfo((response as any).errorDesc);
                    } else {
                        if (response.errors) {
                            console.log(response.desc);

                            // get individual errors
                            if (response.errors.from) {
                                setFrom_fb_Open(true);
                                setFrom_fb_Info(response.errors.from);
                            } else {
                                setFrom_fb_Open(false);
                            }
                            if (response.errors.subject) {
                                setSubject_fb_Open(true);
                                setSubject_fb_Info(response.errors.subject);
                            } else {
                                setSubject_fb_Open(false);
                            }
                            if (response.errors.text) {
                                setMessage_fb_Open(true);
                                setMessage_fb_Info(response.errors.text);
                            } else {
                                setMessage_fb_Open(false);
                            }
                            setFeedbackInfo((response as any).message);
                        } else {
                            console.log('unknown error');
                            setFeedbackInfo('unknown error');
                        }
                    }
                    setFeedbackOpen(true); // open error display feedback
                    recaptchaRef.current?.reset(); // reset reCAPTCHA
                }
            });
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <form
                    onSubmit={(e) => {
                        handleSendClick(e);
                    }}
                >
                    <DialogTitle>Email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>To : {member?.FirstName + ' ' + member?.LastName + ' (' + member?.Institution + ')'}</DialogContentText>
                        <TextField autoFocus margin="dense" id="fromEmail" label="From Email Address" type="email" fullWidth variant="standard" />
                        <Collapse in={from_fb_Open}>
                            <Alert
                                severity={'info'}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setFrom_fb_Open(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {from_fb_Info}
                            </Alert>
                        </Collapse>
                        <TextField margin="dense" id="subject" label="Subject" type="text" fullWidth variant="standard" />
                        <Collapse in={subject_fb_Open}>
                            <Alert
                                severity={'info'}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setSubject_fb_Open(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {subject_fb_Info}
                            </Alert>
                        </Collapse>
                        <TextField margin="dense" id="text" label="Message" type="text" multiline minRows="10" fullWidth variant="standard" />
                        <Collapse in={message_fb_Open}>
                            <Alert
                                severity={'info'}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setMessage_fb_Open(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {message_fb_Info}
                            </Alert>
                        </Collapse>

                        <ReCAPTCHA ref={recaptchaRef} sitekey="6LdkjhUgAAAAAEpDY0KC6ZzyoaCYjvZvSXtW7sAr" onChange={handleRecaptchaChange} />

                        <Collapse in={feedbackOpen}>
                            <Alert
                                severity={feedbackState}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setFeedbackOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {feedbackInfo}
                            </Alert>
                        </Collapse>
                    </DialogContent>

                    <DialogActions>
                        <Button type="submit" disabled={!contactSendEnabled}>
                            Send
                        </Button>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default EmailSendDialog;
