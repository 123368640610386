import { ApiBaseResponse } from '../api/apiTypes';
import { createContext, useContext } from 'react';

export type ApplicationBaseType = {
    base: ApiBaseResponse | null;
    setBase: (b: ApiBaseResponse) => void;
};
export const ApplicationBase = createContext<ApplicationBaseType>({
    base: null, // set a default value
    setBase: (base: ApiBaseResponse | null) => undefined
});
export const useApplicationContext = () => useContext(ApplicationBase);

/*
to use :
    
    import { useApplicationContext } from '../contexts/ApplicationContext';

    const { base, setBase } = useApplicationContext();

*/
