import { createContext, useContext } from 'react';

export type ThemeBaseType = {
    theme: any;
    setTheme: (t: any) => void;
};

export const ThemeBase = createContext<ThemeBaseType>({
    theme: {}, // set a default value
    setTheme: (theme: any) => undefined
});

export const useThemeContext = () => useContext(ThemeBase);

/*
to use :
    
    import { useThemeContext } from '../contexts/ThemeContext';

    const { theme, setTheme } = useThemeContext();

*/
