import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { API } from '../api/apiInterface';
import { Member, URL, URLs } from '../api/apiTypes';
// import MUI components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Backdrop, Button, CircularProgress, Tooltip, Typography, Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Collapse, IconButton } from '@mui/material';
import Image from 'mui-image';
// import utils
import { HTMLtools } from '../classes/utils';
// import icons
import GroupsIcon from '@mui/icons-material/Groups';
import ArticleIcon from '@mui/icons-material/Article';
import LinkIcon from '@mui/icons-material/Link';
import CircleIcon from '@mui/icons-material/Circle';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import ShareIcon from '@mui/icons-material/Share';

// import styles
import { StyledDiv } from '../customisation/theme/styles';
import { columnDef, ColumnDefs, columnType } from '../classes/columns';
// import global contexts
import { useAuthContext } from '../contexts/AuthContext';
import { LayoutMode, useLayoutContext } from '../contexts/LayoutContext';
// import custom components
import EmailSendDialog from '../components/EmailSendDialog';
import ORCIDIcon from '../customisation/icons/ORCIDicon';
// import custom text
import MemberViewHeaderText from '../customisation/text/MemberViewHeaderText';
import { qsp_apiBaseUrl, qsp_layout, qsp_ratoken } from '../customisation/config/config';

const MembersViewPage = () => {
    // use global contexts
    const { auth } = useAuthContext();
    const { layout } = useLayoutContext();
    const location = useLocation();
    const { number: memberId } = useParams(); // organisation path. eg. /easa

    // setup columns
    const [viewMember, setViewMember] = useState<Member | null>(null);

    const [customColumnDefs, setCustomColumnDefs] = useState<ColumnDefs | []>(new Array<columnDef>());

    const [panels, setPanels] = useState<URLs>(new Array<URL>());
    const [papers, setPapers] = useState<URLs>(new Array<URL>());
    const [websiteUrls, setWebsiteUrls] = useState<URLs>(new Array<URL>());
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);
    const [isUrlCopied, setIsUrlCopied] = useState(false);
    const [search] = useSearchParams();

    // panels state
    const [openPanels, setOpenPanels] = React.useState(false);
    const handlePanelsClick = () => {
        // close others
        if (!openPanels) {
            setOpenPapers(false);
            setOpenPersonURLs(false);
        }
        setOpenPanels(!openPanels);
    };
    // papers state
    const [openPapers, setOpenPapers] = React.useState(false);
    const handlePapersClick = () => {
        // close others
        if (!openPapers) {
            setOpenPanels(false);
            setOpenPersonURLs(false);
        }
        setOpenPapers(!openPapers);
    };
    // person URLs state
    const [openPersonURLs, setOpenPersonURLs] = React.useState(false);
    const handlePersonURLsClick = () => {
        // close others
        if (!openPersonURLs) {
            setOpenPanels(false);
            setOpenPapers(false);
        }
        setOpenPersonURLs(!openPersonURLs);
    };

    // loading - pos make a global
    const [loading, setLoading] = useState(true); // set loading = true as dont want to display any data before its there!

    // contact dialog
    const [emailOpen, setEmailOpen] = useState(false);

    const handleEmailClose = () => {
        setEmailOpen(false);
    };

    const handleEmailClick = () => {
        setEmailOpen(true);
    };

    const handleTwitterClick = () => {
        if (viewMember?.Twitter) {
            window.open(viewMember?.Twitter);
        }
    };

    const handleArticleClick = () => {
        window.open(viewMember?.urlDocument);
    };

    const handleORCIDClick = () => {
        window.open(viewMember?.ORCID || '');
    };

    const handleManageAccount = () => {
        switch (layout) {
            case LayoutMode.lm_embedded: {
                const lm = 'Action:manageAccount';
                window.parent.postMessage(lm, '*');
                break;
            }
            case LayoutMode.lm_standalone: {
                window.location.href = API.apiBase.requestContext.urlLogin + '?' + API.apiBase.requestContext.loginParam + '=' + window.location.href;
                break;
            }
        }
    };

    useEffect(() => {
        // _ns post document max heigth to outer wrapper page
        const body = document.body,
            html = document.documentElement,
            height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const msg = 'Height:' + height;
        window.parent.postMessage(msg, '*');
    });

    // run once - load member details for display
    useEffect(() => {
        if (memberId) {
            setLoading(true);

            API.getMemberDetails(memberId).then(async (data) => {
                setViewMember(data.row as Member);

                await setCustomColumnDefs(data.metadata.memberColumnDefs || []);

                // define panels,papers,links etc.
                if (data.row.panelUrls) {
                    setPanels(data.row.panelUrls);
                }
                if (data.row.paperUrls) {
                    setPapers(data.row.paperUrls);
                }
                if (data.row.personUrls) {
                    setWebsiteUrls(data.row.personUrls);
                }

                setLoading(false);
            });
        }
    }, [memberId]);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);

        if (window.innerWidth <= 800) {
            setIsMobile(true);
            return;
        }

        setIsMobile(false);
    };

    useEffect(() => {
        handleWindowSizeChange();

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [width, isMobile]);

    // decode HTML from person Bio string
    const BioText = (): { __html: string } | undefined => {
        let biohtml = '';
        if (viewMember) {
            if (viewMember.Bio) {
                biohtml = HTMLtools.htmlDecode(viewMember.Bio) || '';
            } else {
                biohtml = '';
            }
        } else {
            biohtml = '';
        }
        return { __html: biohtml };
    };

    /*
    size notes:
    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 900px
    lg, large: 1200px
    xl, extra-large: 1536px
    */

    if (!viewMember) {
        return null;
    }

    const handleOpenShare = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (layout === LayoutMode.lm_embedded) {
            // filter search params to remove internal params
            const sm = search;
            if (sm.has(qsp_apiBaseUrl)) {
                sm.delete(qsp_apiBaseUrl);
            }
            if (sm.has(qsp_layout)) {
                sm.delete(qsp_layout);
            }
            if (sm.has(qsp_ratoken)) {
                sm.delete(qsp_ratoken);
            }
            const lm = 'copyURL:' + location.pathname + '?' + sm.toString();

            try {
                await window.parent.postMessage(lm, '*');

                const parentUrl = window.parent.location.href;

                window.parent.navigator.clipboard.writeText(parentUrl);
            } catch (err) {
                console.log({ err });
            }
        } else {
            const url = window.location.href;
            // copy to clipboard
            (async () => {
                await navigator.clipboard.writeText(url);
                setIsUrlCopied(true);
            })();
        }

        setTimeout(() => {
            setIsUrlCopied(false);
        }, 2000);
    };

    const { FirstName, LastName, Institution, Department, JobTitle, urlProfileImage, Twitter, hasDocument, hasProfileImage, ORCID, MembershipID, Bio, personUrls, panelUrls, paperUrls } = viewMember;

    // columns to be mapped over (additional directory data)
    const filteredColumns = customColumnDefs?.filter((i) => i.name !== 'FirstName' && i.name !== 'LastName' && i.name !== 'Department' && i.name !== 'Institution');

    // calc whether any Person urls, Panels or Papers exist. recalc'd on viewMember change.
    const PersonPanelPaperExists = (personUrls && personUrls.length > 0) || (panelUrls && panelUrls.length > 0) || (paperUrls && paperUrls.length > 0);

    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {!loading && (
                <>
                    <Box
                        sx={{
                            margin: '0 auto',
                            maxWidth: '1400px'
                        }}
                    >
                        <Box>
                            <Box sx={{ p: 2 }}>
                                <MemberViewHeaderText />
                            </Box>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={7}>
                                    <Box sx={{ p: 2 }}>
                                        {isMobile ? (
                                            <>
                                                <Typography variant="h5" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>
                                                    {FirstName} {LastName}
                                                </Typography>
                                                <Typography variant="subtitle1" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>
                                                    {Institution}
                                                </Typography>
                                                <Typography variant="subtitle2" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>
                                                    {Department}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <Typography variant="h5" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>{`${FirstName} ${LastName} ${
                                                    Institution ? `(${Institution})` : ''
                                                }`}</Typography>
                                                {/* TODO: when jobtitle is available in api display here */}
                                                {/* format: jobtitle, dept */}
                                                <Typography variant="subtitle1" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>
                                                    {JobTitle && Department ? `${JobTitle}, ${Department}` : JobTitle || Department}
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </Grid>
                                <Grid container direction="row-reverse" item xs={12} sm={5} sx={{ justifyContent: { xs: 'flex-end', sm: 'initial' } }}>
                                    <Box sx={{ p: 2 }}>
                                        <Tooltip title="Email">
                                            <IconButton onClick={handleEmailClick} sx={{ p: 1 }} key="email">
                                                <EmailIcon sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Twitter">
                                            <IconButton disabled={!Twitter} onClick={handleTwitterClick} sx={{ p: 1, opacity: Twitter ? '1.0' : '0.3' }} key="twitter">
                                                <TwitterIcon sx={{ fontSize: 36, color: 'rgb(29, 155, 240)' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="CV">
                                            <IconButton disabled={!(hasDocument === '1')} onClick={handleArticleClick} sx={{ p: 1, opacity: hasDocument === '1' ? '1.0' : '0.3' }} key="document">
                                                <ArticleIcon sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="ORCID">
                                            <IconButton disabled={!ORCID} onClick={handleORCIDClick} sx={{ p: 1, opacity: ORCID ? '1.0' : '0.3' }} key="orcid">
                                                <ORCIDIcon sx={{ fontSize: 36 }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={isUrlCopied ? 'Copied!' : 'Copy URL to clipboard'}>
                                            <IconButton onClick={handleOpenShare} sx={{ p: 0.8 }}>
                                                <ShareIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Grid>
                                <Grid item xs={2}>
                                    <Box sx={{ p: 2 }} key={MembershipID + '-image'}>
                                        {hasProfileImage === '1' && <Image src={urlProfileImage || ''} duration={325} width="150px" height="150px" style={{ borderRadius: 16 }} alt="profile image" />}
                                        {hasProfileImage === '0' && <PersonIcon sx={{ fontSize: 400, width: '150px', height: '150px' }} />}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={7} xl={7}>
                                    <Box sx={{ p: 1 }}>
                                        <Typography variant="h6" noWrap sx={{ p: 1, mr: 2, display: { md: 'flex' } }}>
                                            Bio
                                        </Typography>
                                        {Bio && <StyledDiv dangerouslySetInnerHTML={BioText()} sx={{ p: 1 }} />}
                                        {!Bio && <StyledDiv sx={{ p: 1 }}>None supplied</StyledDiv>}
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={PersonPanelPaperExists ? 5 : 12} md={PersonPanelPaperExists ? 5 : 12} lg={PersonPanelPaperExists ? 5 : 12} xl={PersonPanelPaperExists ? 5 : 12}>
                                    <Box sx={{ p: 1 }}>
                                        {viewMember &&
                                            filteredColumns?.map((colDef) => {
                                                return (
                                                    <React.Fragment key={MembershipID + colDef.name}>
                                                        {(viewMember as any)[colDef.name] && (viewMember as any)[colDef.name] !== '' && (
                                                            <Box>
                                                                {colDef.type === columnType.ct_string && (
                                                                    <Box component="div" sx={{ p: 1 }}>
                                                                        <Typography variant="h6" noWrap sx={{ mr: 2, display: { md: 'flex' } }}>
                                                                            {colDef.alias}
                                                                        </Typography>
                                                                        {(viewMember as any)[colDef.name]}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        )}
                                                    </React.Fragment>
                                                );
                                            })}
                                    </Box>
                                </Grid>
                                {PersonPanelPaperExists && (
                                    <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                                        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav" aria-labelledby="nested-list-subheader" key={'ppp'}>
                                            {websiteUrls && websiteUrls.length > 0 && (
                                                <>
                                                    <ListItemButton onClick={handlePersonURLsClick} key={MembershipID + '-' + 'personList'}>
                                                        <ListItemIcon>
                                                            <LinkIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Websites" />
                                                        {openPersonURLs ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={openPersonURLs} timeout="auto" unmountOnExit key={MembershipID + '-' + 'personListCollapse'}>
                                                        <List component="div" disablePadding key={MembershipID + '-' + 'personSubList'}>
                                                            {personUrls?.map((url) => (
                                                                <ListItemButton
                                                                    key={MembershipID + '-' + url.url}
                                                                    href={url.url}
                                                                    target="_blank"
                                                                    sx={{
                                                                        textDecoration: 'none',
                                                                        pl: 4,
                                                                        '&:hover': {
                                                                            textDecoration: 'underline'
                                                                        }
                                                                    }}
                                                                    component="a"
                                                                >
                                                                    <ListItemIcon>
                                                                        <CircleIcon sx={{ width: '10px' }} />
                                                                    </ListItemIcon>
                                                                    {url.name}
                                                                </ListItemButton>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </>
                                            )}
                                            {panelUrls && panelUrls.length > 0 && (
                                                <>
                                                    <ListItemButton onClick={handlePanelsClick} key={MembershipID + '-' + 'panelList'}>
                                                        <ListItemIcon>
                                                            <GroupsIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Panels" />
                                                        {openPanels ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={openPanels} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding key={MembershipID + '-' + 'panelSubList'}>
                                                            {console.log('Panels')}
                                                            {console.log(JSON.stringify(panels))}
                                                            {panels.map((panel) => (
                                                                <ListItemButton
                                                                    key={MembershipID + '-' + panel.url}
                                                                    href={panel.url}
                                                                    target="_blank"
                                                                    sx={{
                                                                        textDecoration: 'none',
                                                                        pl: 4,
                                                                        '&:hover': {
                                                                            textDecoration: 'underline'
                                                                        }
                                                                    }}
                                                                    component="a"
                                                                >
                                                                    <ListItemIcon>
                                                                        <CircleIcon sx={{ width: '10px' }} />
                                                                    </ListItemIcon>
                                                                    {panel.name}
                                                                </ListItemButton>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </>
                                            )}
                                            {paperUrls && paperUrls.length > 0 && (
                                                <>
                                                    <ListItemButton onClick={handlePapersClick} key={MembershipID + '-' + 'paperList'}>
                                                        <ListItemIcon>
                                                            <ArticleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Papers" />
                                                        {openPapers ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItemButton>
                                                    <Collapse in={openPapers} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding key={MembershipID + '-' + 'paperSubList'}>
                                                            {console.log('Papers')}
                                                            {console.log(JSON.stringify(papers))}
                                                            {papers.map((paper) => (
                                                                <ListItemButton
                                                                    key={MembershipID + '-' + paper.url}
                                                                    href={paper.url}
                                                                    target="_blank"
                                                                    sx={{
                                                                        textDecoration: 'none',
                                                                        pl: 4,
                                                                        '&:hover': {
                                                                            textDecoration: 'underline'
                                                                        }
                                                                    }}
                                                                    component="a"
                                                                >
                                                                    <ListItemIcon>
                                                                        <CircleIcon sx={{ width: '10px' }} />
                                                                    </ListItemIcon>
                                                                    {paper.name}
                                                                </ListItemButton>
                                                            ))}
                                                        </List>
                                                    </Collapse>
                                                </>
                                            )}
                                        </List>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    {auth?.authenticated === 1 && (
                                        <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 2 }}>
                                            <Button key="manageAccount" variant="outlined" onClick={handleManageAccount}>
                                                Manage Account
                                            </Button>
                                        </Box>
                                    )}
                                </Grid>
                            </Grid>

                            <EmailSendDialog member={viewMember} open={emailOpen} handleClose={handleEmailClose} />
                        </Box>
                    </Box>
                </>
            )}
        </div>
    );
};

export default MembersViewPage;
