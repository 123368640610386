import React, { useEffect, useState } from 'react';
// import API & used types
import { Organisation, Authentication } from '../api/apiTypes';
import { API } from '../api/apiInterface';
// import React router components
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
// import hosted pages
import Navbar from './Navbar';
import { qsp_apiBaseUrl, qsp_layout, qsp_ratoken } from '../customisation/config/config';
// import global contexts
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useOrganisationsContext } from '../contexts/OrganisationsContext';
// import global context types for creation
import { AuthBase, AuthBaseType } from '../contexts/AuthContext';
import { getLayoutMode, LayoutBase, LayoutBaseType, LayoutMode, lms_embedded } from '../contexts/LayoutContext';
import { OrganisationBase, OrganisationBaseType } from '../contexts/OrganisationContext';
import { SearchQueryBase, SearchQueryBaseType } from '../contexts/SearchQueryContext';
import { SearchActiveBase, SearchActiveBaseType } from '../contexts/SearchActiveContext';
// import utils
import { getCookie } from '../classes/cookies';
import { virtualHistory } from '../classes/virtualHistory';

const EnvironmentPage = () => {
    // use global contexts
    const { base, setBase } = useApplicationContext();
    const { setOrganisations } = useOrganisationsContext();

    // create states for global context providers
    const [auth, setAuth] = useState<Authentication | null>(null); // global auth context
    const [organisation, setOrganisation] = useState<Organisation | null>(null); // global organisation context
    const [searchQuery, setSearchQuery] = useState(''); // global search query context
    const [searchActive, setSearchActive] = useState(true); // global search query context

    // hook for location
    const location = useLocation();

    // hook for query string processing from URI
    const [layout, setLayout] = useState(LayoutMode.lm_standalone); // global layout context &layout=

    // hook for combined URI query string
    const [search] = useSearchParams();

    // run once - layout / auth setup
    useEffect(() => {
        if (!base) {
            const l = search.get(qsp_layout); // layout = null || 'embedded'
            const t = search.get(qsp_ratoken); // ratoken = null || string

            const entryPoint = location.pathname;
            const entrySearch = location.search;

            if (l != null) {
                API.apiBase.layout = l;

                // SPA support - on entry generate virtual history list allowing back button operation to go up a level if entry point is a sub-page
                if (l === lms_embedded) {
                    virtualHistory.initialise(1, entryPoint, entrySearch); // for embedded mode generate virtual history allowing going back up to organisation members list
                } else {
                    virtualHistory.initialise(0, entryPoint, entrySearch); // for standalone mode generate virtual history allowing going back up to organisation list
                }

                setLayout(getLayoutMode(l));
            } else {
                setLayout(LayoutMode.lm_standalone);

                virtualHistory.initialise(0, entryPoint, entrySearch);
            }

            if (t !== null) {
                API.authToken = t;
                API.apiBase.ratoken = t;
            }

            // Hack to work around login system
            /*
                cookies no longer HTTP only
                so if find 'GlobalAppSession_test' use value to construct ratoken
                eg. session:0q7y40fgn7q084gq
            */
            const authID = getCookie('GlobalAppSession_test');
            if (authID) {
                // set for API call
                API.authToken = 'session:' + authID;
            }

            // if 'apiBaseUrl' provided in search then setup the API to use the specified location
            const a = search.get(qsp_apiBaseUrl); // apiBaseUrl = null || string
            if (a != null) {
                // update API access URL from default stored in config
                API.apiURL = a;
            }

            // if organisation cache exists then populate view using it prior to the API returning any data
            if (API.getBaseOrganisationsCache()) {
                setOrganisations(API.apiBaseCacheOrganisations);
            } else {
                console.log('base.organisations cache NOT found');
            }

            // get boot data
            API.getBase().then((data) => {
                setBase(data);

                // if organisations changed then update
                setOrganisations(data.organisations);

                API.setBaseOrganisationsCache();
            });
        }
    }, []);

    // run on base application context changed
    useEffect(() => {
        if (base) {
            /*
                handle authentication
                nb. login/out leaves site & then returns
                    as such the app will be rebuilt, meaning that the base object will correctly define the logged in state
            */
            setAuth(base.authentication);
        }
    }, [base]);

    return (
        <div>
            <LayoutBase.Provider value={{ layout, setLayout } as unknown as LayoutBaseType}>
                <AuthBase.Provider value={{ auth, setAuth } as unknown as AuthBaseType}>
                    <SearchActiveBase.Provider value={{ searchActive, setSearchActive } as unknown as SearchActiveBaseType}>
                        <SearchQueryBase.Provider value={{ searchQuery: searchQuery, setSearchQuery } as unknown as SearchQueryBaseType}>
                            <OrganisationBase.Provider value={{ organisation, setOrganisation } as unknown as OrganisationBaseType}>
                                <Navbar />
                            </OrganisationBase.Provider>
                        </SearchQueryBase.Provider>
                    </SearchActiveBase.Provider>
                </AuthBase.Provider>
            </LayoutBase.Provider>
        </div>
    );
};

export default EnvironmentPage;
