import { useNavigate } from 'react-router-dom';

export type virtualURLs = string[];

/*

Notes:

The virtual history class is designed to provide back functionality when the app is launched from a single URL which is not at the top level.
ie. if the user shares a member profile URL.
This allows them to navigate back up the hierarchy. eg. member profile > members directory > organisation directory.

*/

export class VirtualHistory {
    public virtual: virtualURLs = new Array<string>();
    public index = 0;

    initialise(depthStart: number, path: string, search: string) {
        // initialise virtual history from initial location
        // depthStart defines the top level the user can go back to, in terms of URL path segments.
        // nb. this allows a members dir limit when in embedded mode
        this.virtual.length = 0; // clear array
        const hpa = path.split('/');
        let root = '';
        const depth = hpa.length;
        for (let pi = depthStart; pi < depth; pi++) {
            const p = hpa[pi];

            let url = '';
            let path = '';

            if (p == '') {
                path = '/';
                // if depth > 1, assume search terms apply to lower levels so remove for top level
                // nb. still allows url copy to work
                if (depth > 1) {
                    search = '';
                }
            } else {
                path = p;
            }

            url = root + path + search;
            root += p + '/';
            console.log(url);
            this.virtual.push(url);
        }

        console.log(JSON.stringify(hpa));
        this.index = this.virtual.length - 1; // current index = entry page
    }
}

export enum VirtualHistoryOp {
    vho_Forward,
    vho_Back,
    vho_Goto
}
export function useVirtualHistory() {
    const navigate = useNavigate();
    const vh_forward = () => {
        console.log('vh_forward');
        virtualHistory.index++;
        if (virtualHistory.index > virtualHistory.virtual.length - 1) {
            navigate(1);
        } else {
            if (virtualHistory.index > 0) {
                navigate(virtualHistory.virtual[virtualHistory.index]);
            }
        }
    };
    const vh_back = () => {
        navigate(-1);

        // TODO:
        // Leave for now, investigate later
        // try {
        //     if (virtualHistory.index > 0) {
        //         virtualHistory.index--;
        //         if (virtualHistory.index > virtualHistory.virtual.length - 1) {
        //             navigate(-1);
        //         } else {
        //             navigate(virtualHistory.virtual[virtualHistory.index]);
        //         }
        //     } else {
        //         navigate(-1);
        //     }
        // } catch (error) {
        //     navigate(-1);
        // }
    };

    const vh_goto = (url: string) => {
        console.log('vh_goto');
        navigate(url);

        // TODO:
        // Leave for now, investigate later
        /*
            if within the virtual history array shorten array at current position
            eg. 
            
            d
            c - goto(x) = rem d & goto x. ie. d = x. length-1. 
            b
            a

            d - goto(x) = rem nothing & goto x.
            c
            b
            a
        // */
        // if (virtualHistory.index < virtualHistory.virtual.length) {
        //     virtualHistory.virtual.splice(virtualHistory.index + 1); // remove all entries after current index
        //     virtualHistory.index++;
        // } else {
        //     virtualHistory.index++;
        // }
        // navigate(url);
    };
    const vh_registerURLchange = () => {
        // register eg. search param change
        console.log('vh_registerURLchange');
        if (virtualHistory.index < virtualHistory.virtual.length) {
            virtualHistory.virtual.splice(virtualHistory.index + 1); // remove all entries after current index
        }
        virtualHistory.index++;
    };
    return { vh_forward, vh_back, vh_goto, vh_registerURLchange };
}

// Export an instance of the class indirectly
export const virtualHistory = new VirtualHistory();
