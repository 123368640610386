import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Paper, Table, TableCell, TablePagination, TableRow, tableRowClasses, Toolbar, Tooltip } from '@mui/material';
//import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';
import { defaultTheme } from './defaultStyle';
import { Table as VirtualizedTable } from 'react-virtualized';

//import { useThemeContext } from '../../contexts/ThemeContext';

//const { theme, setTheme } = useThemeContext();

export const theme = defaultTheme;

// base theme

/*
export const theme = createTheme({
    palette: {
        primary: {
            main: '#a7c3d7'
        }
    }
});
*/

/*
    const theme = createTheme({
        palette: {
            primary: {
                light: '#63b8ff',
                main: '#0989e3',
                dark: '#005db0',
                contrastText: '#000'
            },
            secondary: {
                main: '#4db6ac',
                light: '#82e9de',
                dark: '#00867d',
                contrastText: '#000'
            }
        }
    });
*/

/*
export const theme = createTheme({
    palette: {
        primary: {
            main: purple[500]
        },
        secondary: {
            main: green[100] // nav bar background
        }
    }
});
*/
/*
export const theme = createTheme({
    palette: {
        primary: {
            main: purple[500]
        },
        secondary: {
            main: green[100] // nav bar background
        }
    }
});
*/
/*
let themeBase = createTheme({
    palette: {
        primary: {
            main: purple[500]
        },
        secondary: {
            main: green[100] // nav bar background
        }
    }
});
export const theme = responsiveFontSizes(themeBase);
*/

// nav bar styles

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: '54px'
}));

export const StyledToolbarRight = styled(Table)(({ theme }) => ({
    flexDirection: 'row-reverse'
}));

export const ToolbarSubRight = styled('div')(({ theme }) => ({}));

export const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    '&:focus': {
        backgroundColor: alpha(theme.palette.common.white, 0.3)
    },
    ':autofill': {
        backgroundColor: alpha(theme.palette.common.white, 0.3)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto'
    }
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '16ch',
            '&:focus': {
                width: '24ch'
            }
        }
    }
}));

// members view styles

export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
}));

// list view styles

export const StyledTable = styled(Table)(({ theme }) => ({}));
export const StyledVirtualizedTable = styled(VirtualizedTable)({
    '.MuiTableCell-root': {
        border: 'none'
    },
    '.ReactVirtualized__Table__row': {
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            cursor: 'pointer'
        },
        ':focus': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            outline: 'none'
        }
    }
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    cursor: 'pointer',
    ':focus': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        outline: 'none'
    }
}));

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
    fontSize: '0.845rem',
    fontWeight: 'bold',
    height: '50px'
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    padding: '0px 16px',
    fontSize: '0.845rem',
    height: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

export const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
    height: '30px',
    padding: '0px'
}));
export const StyledTableFooterRow = styled(TableRow)(({ theme }) => ({}));
export const StyledTablePagination = styled(TablePagination)(({ theme }) => ({}));

// sub-table for pagination control

export const StyledSubTable = styled(Table)(({ theme }) => ({
    width: 'max-content'
}));
export const StyledSubTableCell = styled(TableCell)(({ theme }) => ({
    borderBottom: 'none',
    overflow: 'visible'
}));
export const StyledSubTablePagination = styled(TablePagination)(({ theme }) => ({
    borderBottom: 'none',
    overflow: 'visible'
}));

// NavBar

export const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    //width: 'unset'
}));
export {};

// Members view

export const StyledDiv = styled('div')(({ theme }) => ({
    textAlign: 'justify'
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
    height: '30px',
    width: '30px',
    fontSize: '0.8rem'
}));
