import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
// import hosted pages
import MembersPage from './MembersPage/Members';
import MembersViewPage from './MembersView';
// import global contexts
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useOrganisationContext } from '../contexts/OrganisationContext';
import { useThemeContext } from '../contexts/ThemeContext';
import { createTheme } from '@mui/material';

const OrganisationPage = () => {
    // use global contexts
    const { base } = useApplicationContext();
    const { organisation, setOrganisation } = useOrganisationContext();
    const { setTheme } = useThemeContext();

    const { orgSlug } = useParams(); // organisation path. eg. /easa

    // called on orgSlug change
    useEffect(() => {
        // find organisation from orgSlug
        if (base) {
            if (base.organisations) {
                for (const o of base.organisations) {
                    if (o.Slug === orgSlug) {
                        if (o.theme) {
                            setTheme(createTheme(o.theme));
                        }
                        setOrganisation(o);
                        break;
                    }
                }
            } else {
                console.log('organisations unset');
            }
        }
    }, [orgSlug, base, setOrganisation, setTheme]);

    return (
        <div>
            <Routes>
                <Route index element={<MembersPage org={organisation} />} />
                <Route path=":number" element={<MembersViewPage />} />
            </Routes>
        </div>
    );
};

export default OrganisationPage;
