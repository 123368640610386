export const default_apiRoot = 'https://nomadit.co.uk/api/member_directory_v1/';

// requried for use on localhost
export const apiDevSecret = 'j6onu05jehhco613qxe';
// in production unset...
//export const apiDevSecret: string = '';

// query string param names
export const qsp_apiBaseUrl = 'apiBaseUrl';
export const qsp_layout = 'layout';
export const qsp_ratoken = 'ratoken';

export {};
