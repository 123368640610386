export class StringUtils {
    // pad('0000000000',123,true);
    // http://stackoverflow.com/questions/2686855/is-there-a-javascript-function-that-can-pad-a-string-to-get-to-a-determined-leng

    static pad(pad: string, str: string, padLeft: boolean) {
        if (typeof str === 'undefined') return pad;
        if (padLeft) {
            return (pad + str).slice(-pad.length);
        } else {
            return (str + pad).substring(0, pad.length);
        }
    }
}

export class TimeUtils {
    static secsDuration(secs: number) {
        const minutes = Math.floor(secs / 60);
        const seconds = secs - minutes * 60;
        const hours = Math.floor(secs / 3600);
        secs = secs - hours * 3600;
        let ts = '';
        if (hours > 0) {
            ts += StringUtils.pad('00', String(Math.round(hours)), true) + ' hr ';
        }
        if (minutes > 0) {
            ts += StringUtils.pad('00', String(Math.round(minutes)), true) + ' min ';
        }
        ts += StringUtils.pad('00', String(Math.round(seconds)), true) + ' sec ';
        return ts;
    }
    static _MS_PER_DAY = 1000 * 60 * 60 * 24;
    static _MS_PER_HOUR = 1000 * 60 * 60;
    static _MS_PER_MINUTE = 1000 * 60;

    // a and b are javascript Date objects
    static dateDiffInDays(a: Date, b: Date): number {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / TimeUtils._MS_PER_DAY);
    }
    static dateDiffInHours(a: Date, b: Date): number {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / TimeUtils._MS_PER_HOUR);
    }
    static dateDiffInMinutes(a: Date, b: Date): number {
        // Discard the time and time-zone information.
        const utc2 = Date.UTC(a.getUTCFullYear(), a.getUTCMonth(), a.getUTCDate(), a.getUTCHours(), a.getUTCMinutes(), a.getUTCSeconds());
        const utc1 = Date.UTC(b.getUTCFullYear(), b.getUTCMonth(), b.getUTCDate(), b.getUTCHours(), b.getUTCMinutes(), b.getUTCSeconds());
        return Math.floor((utc2 - utc1) / TimeUtils._MS_PER_MINUTE);
    }
}

export class URLtools {
    /*
        nb.
        wont accept eg. www.ethosantropologico.com as doesnt have http:// / https:// at start 
        so need to account for this.

        * fully qualified URLs to be provided by API
    */
    static isValidHttpUrl(urlString: string) {
        let url;
        try {
            url = new URL(urlString);
        } catch (_) {
            return false;
        }
        console.log(JSON.stringify(url));
        return url.protocol === 'http:' || url.protocol === 'https:';
    }
}

export class HTMLtools {
    static htmlDecode(content: string): string | null {
        const e = document.createElement('div');
        e.innerHTML = content;
        //return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
        return e.innerHTML;
    }
}
