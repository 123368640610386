import { createContext, useContext } from 'react';

export type SearchQueryBaseType = {
    searchQuery: string;
    setSearchQuery: (s: string) => void;
};
export const SearchQueryBase = createContext<SearchQueryBaseType>({
    searchQuery: '', // set a default value
    setSearchQuery: (s: string) => undefined
});
export const useSearchQueryContext = () => useContext(SearchQueryBase);

/*
to use :
    
    import { useSearchQueryContext } from '../contexts/SearchQueryContext';

    const { searchQuery, setSearchQuery } = useSearchQueryContext();

*/
