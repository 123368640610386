import { apiDevSecret, default_apiRoot as apiDirectoryURL } from '../customisation/config/config';
import { TimeUtils } from '../classes/utils';
import {
    ApiAuthResponse,
    ApiBaseResponse,
    ApiDirectoryResponse,
    ApiDirectoryResponseNoRows,
    ApiEmailRepsonse,
    ApiMemberDetailsResponse,
    ApiOrgansationResponse,
    Authentication,
    Metadata,
    organisations,
    RequestContext
} from './apiTypes';

/*
    Design notes:

    API exported as a single class instance through this module which is used by all pages.
    Operations are performed asynchronously to allow immediate display & then update on data being returned from API
    leading to faster user experience

*/

export class globalState {
    public API: APIclass | null = null;
}

export class APIclass {
    public apiURL: string;
    public orgSlug: string | null = null;
    public authToken: string | null = null;
    public authDevToken: string | null = apiDevSecret;
    // api responses
    public apiBase = {} as ApiBaseResponse; // initial bootstrap data. nb. inc. login/logout URLs
    public apiBaseCacheOrganisations = {} as organisations; //"".organisations

    public requestContext: RequestContext | null = null;
    public authentication: Authentication | null = null;
    public metadata: Metadata | null = null;

    // auth handling
    public defCredentials: RequestCredentials | undefined = 'same-origin';

    public directoryRefreshIntervalMinutes = 60; // FUTURE TODO: load from config / API

    public apiDirectory = {} as ApiDirectoryResponse; // complete directory response with rows, used for members list display
    public apiDirectoryNR = {} as ApiDirectoryResponseNoRows; // directory response with no rows, used to check rows md5hash
    public apiDirectoryNRLastReq = new Date();
    public apiCache = {} as ApiDirectoryResponse; // cached copy of complete directory response, used if no connection is available or md5hash matches current
    public apiAuth = {} as ApiAuthResponse; // authorisation response
    public apiOrg = {} as ApiOrgansationResponse; // organisation details response
    constructor(apiURL: string) {
        this.apiURL = apiURL;
    }

    // generic promise based aync api GET
    apiGET<Type>(functionCall: string) {
        return fetch(this.apiURL + functionCall, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-remote-dev-access-secret': this.authDevToken || '',
                'x-ratoken': this.authToken || ''
            },
            credentials: this.defCredentials
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                return data; // nb. already an object
            })
            .catch((error) => {
                console.log('Error ' + error);
                return { errorDesc: '[network error] ' + error } as unknown as Type;
            });
    }
    // generic promise based aync api POST
    apiPOSTFORM<Type>(functionCall: string, formData: FormData) {
        return fetch(this.apiURL + functionCall, {
            method: 'POST',
            headers: {
                'x-remote-dev-access-secret': this.authDevToken || '',
                'x-ratoken': this.authToken || ''
            },
            body: formData,
            credentials: this.defCredentials
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                return data; // nb. already an object
            })
            .catch((error) => {
                console.log('Error ' + error);
                return { errorDesc: '[network error] ' + error } as unknown as Type;
            });
    }

    // BOOT

    //https://test.nomadit.co.uk/api/member_directory_v1/

    getBase(): Promise<ApiBaseResponse> {
        return this.apiGET<ApiBaseResponse>('').then((data) => {
            console.log('Success:', data);
            this.apiBase = data;
            return this.apiBase;
        });
    }
    sendEmail(emailForm: FormData): Promise<ApiEmailRepsonse> {
        return this.apiPOSTFORM<ApiEmailRepsonse>('message', emailForm).then((data) => {
            console.log('Success:', data);
            return data;
        });
    }

    setOrganisation(orgSlug: string): Promise<ApiDirectoryResponse> {
        this.orgSlug = orgSlug;
        console.log('api.setOrganisation');

        // new version
        if (this.getOrganisationCache()) {
            console.log('  ' + orgSlug + ': cache found');
            if (this.getOrganisationCacheValid()) {
                console.log('  cache age OK : using cache');
                this.apiDirectory = this.apiCache;
                return Promise.resolve(this.apiDirectory);
            } else {
                console.log('  cache too old : retriving data from server');
                // get all data
                /*
                    future implementation
                    pass md5hash from apiDirectory ? as query string ? to getDirectory & if md5hash matches current no rows are returned
                */
                return this.getDirectory('').then((data) => {
                    if (!data.errorDesc?.includes('[network error]')) {
                        /*
                            future implementation
                            if rows dont exist, means just use cache
                        */
                        this.apiDirectory = data;
                        console.log('Success:', data);
                        this.setOrganisationCache();
                    } else {
                        console.log('Fetch failed:', data);
                        this.apiDirectory = this.apiCache;
                    }
                    return this.apiDirectory;
                });
            }
        } else {
            // no date, so get all data
            /*
                future implementation
                no need to send mdhash
            */
            console.log('  no cache found : retriving data from server');
            return this.getDirectory('').then((data) => {
                if (!data.errorDesc?.includes('[network error]')) {
                    this.apiDirectory = data;
                    console.log('Success:', data);
                    this.setOrganisationCache();
                    return this.apiDirectory;
                } else {
                    return {} as ApiDirectoryResponse;
                }
            });
        }
    }

    getOrganisationCache(): boolean {
        // get saved results from 'GET /api/member_directory_v1/directory/{oid}' if available
        if (this.orgSlug) {
            const ars = localStorage.getItem(this.orgSlug || '');
            if (ars != null) {
                this.apiCache = JSON.parse(ars);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getOrganisationCacheValid(): boolean {
        // get saved results from 'GET /api/member_directory_v1/directory/{oid}' if available
        if (this.orgSlug) {
            let cacheValid = true;
            const ctss = localStorage.getItem(this.orgSlug + '-LRT');
            if (ctss) {
                const ds = Date.parse(ctss);
                if (!isNaN(ds)) {
                    const cts = new Date(ds); // org : last request time
                    const cam = TimeUtils.dateDiffInMinutes(new Date(), cts);

                    console.log(this.orgSlug + ' : cache age = ' + cam.toString() + ' mins');

                    if (cam > this.directoryRefreshIntervalMinutes) {
                        cacheValid = false;
                    }
                }
            } else {
                cacheValid = false;
            }
            return cacheValid;
        } else return false;
    }

    setOrganisationCache(): boolean {
        // save results from 'GET /api/member_directory_v1/directory/{oid}'
        if (this.orgSlug) {
            const ars = JSON.stringify(this.apiDirectory);
            localStorage.setItem(this.orgSlug || '', ars);
            localStorage.setItem(this.orgSlug + '-LRT', new Date().toISOString());
            return true;
        } else {
            return false;
        }
    }

    // return copy of base cache if exists - used to preload everything while waiting for data
    getBaseOrganisationsCache(): boolean {
        const ars = localStorage.getItem('base.organisations');
        if (ars) {
            this.apiBaseCacheOrganisations = JSON.parse(ars);
            return true;
        } else {
            return false;
        }
    }
    setBaseOrganisationsCache(): boolean {
        if (this.apiBase) {
            const os = JSON.stringify(this.apiBase.organisations);
            if (os) {
                localStorage.setItem('base.organisations', os);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    setAuthToken(authToken: string): Promise<ApiAuthResponse> {
        this.authToken = authToken;
        return this.getAuthentication().then((data) => {
            this.apiAuth = data;
            console.log('Success:', data);
            return this.apiAuth;
        });
    }
    getAuthState(): Promise<ApiAuthResponse> {
        return this.getAuthentication().then((data) => {
            this.apiAuth = data;
            console.log('Success:', data);
            return this.apiAuth;
        });
    }

    getDirectory(queryString: string): Promise<ApiDirectoryResponse> {
        return this.apiGET<ApiDirectoryResponse>('directory/' + this.orgSlug + '?' + queryString).then((data) => {
            console.log('Success:', data);
            return data;
        });
    }

    getAuthentication() {
        return this.apiGET<ApiAuthResponse>('index').then((data) => {
            console.log('Success:', data);
            return data;
        });
    }

    getMemberDetails(memberId: string): Promise<ApiMemberDetailsResponse> {
        return this.apiGET<ApiMemberDetailsResponse>('member_details/' + memberId).then((data) => {
            console.log('Success:', data);
            return data;
        });
    }
}

// Export an instance of the class indirectly
export const API = new APIclass(apiDirectoryURL);
