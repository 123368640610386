import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { defaultTheme } from './customisation/theme/defaultStyle';
import { ApplicationBase, ApplicationBaseType } from './contexts/ApplicationContext';
import { OrganisationsBase, OrganisationsBaseType } from './contexts/OrganisationsContext';
import { ThemeBase, ThemeBaseType } from './contexts/ThemeContext';
import { ApiBaseResponse, organisations } from './api/apiTypes';
import EnvironmentPage from './pages/Environment';

const Application: React.FC = () => {
    // create states for global context providers
    const [base, setBase] = useState<ApiBaseResponse | null>(null);
    const [organisations, setOrganisations] = useState<organisations | null>(null);
    const [theme, setTheme] = useState<Theme>(defaultTheme);

    return (
        <ThemeBase.Provider value={{ theme, setTheme } as unknown as ThemeBaseType}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        minHeight: '100vh',
                        maxWidth: '100vw',
                        flexGrow: 1
                    }}
                >
                    <ApplicationBase.Provider value={{ base, setBase } as unknown as ApplicationBaseType}>
                        <OrganisationsBase.Provider value={{ organisations, setOrganisations } as unknown as OrganisationsBaseType}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/*" element={<EnvironmentPage />} />
                                </Routes>
                            </BrowserRouter>
                        </OrganisationsBase.Provider>
                    </ApplicationBase.Provider>
                </Box>
            </ThemeProvider>
        </ThemeBase.Provider>
    );
};

export default Application;
