import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Paper, Table, TableCell, TablePagination, TableRow, tableRowClasses, Toolbar, Tooltip } from '@mui/material';
//import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: purple[500]
        },
        secondary: {
            main: green[100] // nav bar background
        }
    }
});
