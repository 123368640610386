import { createContext, useContext } from 'react';

export type SearchActiveBaseType = {
    searchActive: boolean;
    setSearchActive: (s: boolean) => void;
};
export const SearchActiveBase = createContext<SearchActiveBaseType>({
    searchActive: true, // set a default value
    setSearchActive: (s: boolean) => undefined
});
export const useSearchActiveContext = () => useContext(SearchActiveBase);

/*
to use :
    
    import { useSearchActiveContext } from '../contexts/SearchActiveContext';

    const { searchActive, setSearchActive } = useSearchActiveContext();

*/
