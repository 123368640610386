import { columnDef, ColumnDefs, Columns, ColumnSizeType, columnType } from './columns';
import { Member, Organisation } from '../api/apiTypes';
import { exportToCsv } from './csv';
import { default_apiRoot } from '../customisation/config/config';

/*
    Design notes:

    TableColumns<T> provides management of 
    - column sizing
    - header aliases
    - caching filtered data for export
    - export to csv

*/

export type TableColumnsFieldMap = Map<string, string>;

export class TableColumns<T> {
    public columns: Columns = new Columns();
    public columnDefs: ColumnDefs = new Array<columnDef>();
    public IDfield = '';
    public customFieldMap: TableColumnsFieldMap = new Map<string, string>();

    public customColumns: Columns = new Columns();
    public customColumnDefs: ColumnDefs = new Array<columnDef>();

    public filteredData: Array<T> = new Array<T>(); // filtered data source

    constructor(IDfield: string) {
        this.IDfield = IDfield;
    }
    generateColumnDefs() {
        this.columns.calculateColumns();
        this.columnDefs = this.columns.defs;
    }
    generateCustomColumnDefs() {
        this.customColumns.calculateColumns();

        this.customColumnDefs = this.customColumns.defs;
    }

    confgureColumns(cd: ColumnDefs | []) {
        //
        this.columns.reset();
        this.customColumns.reset();

        const defVariableColWidth = 100 / cd.length;

        cd.forEach((col) => {
            let ct = columnType.ct_string;
            switch (col.type as any) {
                case 'avatar':
                    ct = columnType.ct_avatar;
                    break;
                case 'string':
                    ct = columnType.ct_string;
                    break;
                case 'link':
                    ct = columnType.ct_link;
                    break;
                case 'image':
                    ct = columnType.ct_image;
                    break;
                default:
            }
            //this.columns.add(col.name, col.alias, true, col.type, col.order, columnSizeType.cst_Variable, defVariableColWidth);

            // TODO: use cd.widthType & cd.width once implemented by Neil

            this.columns.add(col.name, col.alias, true, ct, col.order, ColumnSizeType.cst_Variable, defVariableColWidth);

            if (col.name.includes('Field')) {
                this.customColumns.add(col.name, col.alias, true, ct, col.order, ColumnSizeType.cst_Variable, defVariableColWidth);
            }
        });

        /*

            Calculate total fixed column widths in px so this can be used in vw calcs eg. calc(calc(100vw-64px) * 0.2)

        */
        let fixedWidthTotal = 0;
        cd.forEach((col) => {
            if (col.widthType == ColumnSizeType.cst_Fixed) {
                fixedWidthTotal += col.width;
            }
        });

        /*
        nb. detect Field** columns as these are special & to be displayed in the member view
        */
    }
    columnWidth(colName: string): any {
        return this.columns.columnWidth(colName);
    }
    filterData = (query: string, data: Array<T> | null, idField: string, searchCache: Map<string, string>) => {
        if (data != null) {
            if (!query) {
                this.filteredData = data;
                return data;
            } else {
                const q = query.toLowerCase();
                // multi-search comma delimited
                const qsa = q.split(',').map((element) => {
                    return element.trim();
                });
                this.filteredData = data.filter((d: T) => {
                    let match = true;
                    for (const qs of qsa) {
                        if (!searchCache.get((d as any)[idField])?.includes(qs)) {
                            match = false;
                            break;
                        }
                    }
                    return match;
                });
                return this.filteredData;
            }
        } else {
            return new Array<T>();
        }
    };
    exportCSV(filename: string) {
        const headers = new Array<string>();
        this.columnDefs?.map((colDef) => {
            if (colDef.visible && !colDef.derived) {
                headers.push(colDef.alias);
            }
        });
        const exportData = new Array<object>();
        this.filteredData?.map((row) => {
            const d: object = {};
            this.columnDefs?.map((colDef) => {
                if (colDef.visible && colDef.type == columnType.ct_string) {
                    (d as any)[colDef.alias] = (row as any)[colDef.name];
                }
            });
            exportData.push(d);
        });
        exportToCsv(filename, exportData as any, headers);
    }
}

export class membersTableColumns extends TableColumns<Member> {
    constructor() {
        super('PersonID');
    }
    iniitaliseDerivedColumns() {
        // add calculated field
        this.columns.add('avatar', 'Email', true, columnType.ct_avatar, -1, ColumnSizeType.cst_Fixed, 64, (data: Member): string => {
            return default_apiRoot + 'files_v1/person_profile_image/' + data.PersonID + '/md_list';
        });
    }
}

export class organisationTableColumns extends TableColumns<Organisation> {
    constructor() {
        super('OrganisationID');
        this.columns.add('Logo', '', true, columnType.ct_image, 0, ColumnSizeType.cst_Fixed, 64);
        this.columns.add('Title', 'Organisation', true, columnType.ct_string, 1, ColumnSizeType.cst_Variable, 64);
        this.columns.add('Slug', 'Slug', false, columnType.ct_string, 2, ColumnSizeType.cst_Variable, 64);
        this.columns.add('OrganisationID', 'OrganisationID', false, columnType.ct_string, 3, ColumnSizeType.cst_Variable, 64);
        this.columns.add('ShortName', 'ShortName', false, columnType.ct_string, 4, ColumnSizeType.cst_Variable, 64);
        // generate support data
        //this.generateColumnDefs();
        //this.generateCustomFieldMap();
    }
}
