import React, { useEffect, useState, useRef, MouseEvent, KeyboardEvent } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Column, defaultTableRowRenderer } from 'react-virtualized';
import { Backdrop, CircularProgress, Paper, TableSortLabel, Tooltip, Box, TableContainer, IconButton, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { StyledVirtualizedTable } from '../../customisation/theme/styles';

// import API & used types
import { API } from '../../api/apiInterface';
import { Organisation, Member } from '../../api/apiTypes';

// import styles
import { StyledTableHeaderCell } from '../../customisation/theme/styles';

import { columnDef, ColumnDefs, columnType } from '../../classes/columns';
import { membersTableColumns } from '../../classes/tableColumns';

// import global contexts
import { useOrganisationContext } from '../../contexts/OrganisationContext';
import { useSearchQueryContext } from '../../contexts/SearchQueryContext';

export type Order = 'asc' | 'desc';

export interface IMembersPageProps {
    org: Organisation | null;
}

const MembersPage: React.FunctionComponent<IMembersPageProps> = ({ org }) => {
    // use global contexts
    const { organisation } = useOrganisationContext();
    const { searchQuery, setSearchQuery } = useSearchQueryContext(); // &filter=

    const navigate = useNavigate();

    // setup columns
    const viewData = useRef<membersTableColumns>(new membersTableColumns());

    const [columnDefs, setColumnDefs] = useState<ColumnDefs>(new Array<columnDef>());
    const [membersData, setMembersData] = useState<Member[]>(new Array<Member>());
    const [searchCache, setSearchCache] = useState<Map<string, string>>(new Map<string, string>());
    const [sortingOrderDirection, setSortingOrderDirection] = useState<Order>('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('LastName');

    const [ratoken, setratoken] = useState(''); // &ratoken=

    // hook for combined URI query string
    const [search] = useSearchParams();

    const [loading, setLoading] = useState(true); // set loading = true as dont want to display any data before its there!

    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        // _ns post document max heigth to outer wrapper page
        const body = document.body,
            html = document.documentElement,
            height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const msg = 'Height:' + height;
        window.parent.postMessage(msg, '*');
    });

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);

        if (window.innerWidth <= 800) {
            setIsMobile(true);
            return;
        }

        setIsMobile(false);
    };

    useEffect(() => {
        handleWindowSizeChange();

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [width, isMobile]);

    // run on organisation change
    useEffect(() => {
        if (organisation) {
            setLoading(true);

            // initialise API & get data
            API.authDevToken = 'j6onu05jehhco613qxe';

            API.setOrganisation(organisation.Slug)
                .then((data) => {
                    if (data.rows == null) {
                        data.rows = new Array<Member>();
                    }

                    // initialise data view
                    viewData.current.confgureColumns(data.metadata.listingColumnDefs || []);
                    viewData.current.generateColumnDefs();

                    setColumnDefs(viewData.current.columnDefs);
                    setMembersData(data.rows);
                    setSearchCache(viewData.current.columns.generateSearchCacheGeneric(data.rows, 'PersonID'));

                    setLoading(false);
                })
                .catch((err) => console.error(err));
        } else {
            setMembersData(new Array<Member>());
        }
    }, [organisation]); // nb. allow multiple changes as organisation change > 1

    // run on search URL params change
    useEffect(() => {
        const t = search.get('ratoken'); // ratoken = null || string
        const fq = search.get('filter') || ''; // filter query

        if (t !== null) {
            API.setAuthToken(t).then((auth) => {
                setratoken(t || '');
            });
        }

        setSearchQuery(fq);
    }, [search, setSearchQuery]);

    const handleClick = (e: MouseEvent | KeyboardEvent, MembershipID: string) => {
        e?.stopPropagation();
        navigate(`${MembershipID}?${search}`);
    };

    // calculate filtered data. nb. updated on parameter changes
    const dataFiltered = viewData.current.filterData(searchQuery, membersData, 'PersonID', searchCache);

    const downloadMembersCSV = () => {
        viewData.current.exportCSV('Members.csv');
    };

    const handleRequestSort = (property: string) => {
        const isAscending = valueToOrderBy === property && sortingOrderDirection === 'asc';
        setValueToOrderBy(property);
        setSortingOrderDirection(isAscending ? 'desc' : 'asc');
    };

    const columnsWithSorting = ['FirstName', 'LastName', 'Country'];

    const descendingComparator = (a: any, b: any, orderBy: string) => {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }

        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }

        return 0;
    };

    const getComparator = (order: Order, orderBy: string) => {
        return order === 'desc' ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy);
    };

    const sortedArr = dataFiltered.slice().sort(getComparator(sortingOrderDirection, valueToOrderBy));

    // filter out websute from columns as soon it won't exist
    const filteredColumnDefs = columnDefs.filter(({ type, alias }) => type !== columnType.ct_link && alias.toLowerCase() !== 'website');

    if (loading) {
        return (
            <Backdrop tabIndex={-2} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <Box
            sx={{
                padding: {
                    xs: '0',
                    sm: '0 40px'
                }
            }}
        >
            <Box sx={{ paddingLeft: '30px', display: 'flex', alignItems: isMobile ? 'flex-end' : 'center', gap: '10px', marginTop: '50px' }}>
                <Box
                    sx={{
                        marginTop: '10px',
                        color: '#707070',
                        display: 'flex',
                        alignItems: isMobile ? 'flex-end' : 'center',
                        gap: '6px'
                    }}
                >
                    {searchQuery ? `${sortedArr.length} members showing of ${membersData.length}` : `${sortedArr.length} members total`}

                    <Typography variant="body1" sx={{ flexGrow: 1, color: '#707070' }}>
                        (click on a name to view details)
                    </Typography>
                </Box>

                <Tooltip title="Download members results as csv">
                    <IconButton size="small" onClick={downloadMembersCSV} sx={{ p: 0.8, marginTop: '9px', padding: '3' }}>
                        <FileDownloadIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </Box>

            <TableContainer
                sx={{
                    height: '80vh',
                    width: '92vw',
                    margin: '0 auto',
                    overflowX: 'auto'
                }}
            >
                {isMobile ? (
                    <Paper
                        sx={{
                            height: '100vh',
                            width: '100vw',
                            margin: '0 auto',
                            overflowX: 'auto'
                        }}
                    >
                        <AutoSizer>
                            {({ height }) => (
                                <StyledVirtualizedTable
                                    tabIndex={-1}
                                    width={filteredColumnDefs.length * 100}
                                    height={height}
                                    headerHeight={60}
                                    rowHeight={50}
                                    rowCount={sortedArr.length}
                                    rowGetter={({ index }) => sortedArr[index]}
                                    onRowClick={({ rowData, event }) => {
                                        handleClick(event, rowData.MembershipID);
                                    }}
                                    rowStyle={{
                                        borderBottom: '1px solid rgba(224, 224, 224, 1)'
                                    }}
                                    rowRenderer={(props) => (
                                        <span
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleClick(e, props.rowData.MembershipID);
                                                }
                                            }}
                                            key={props.rowData.MembershipID}
                                            style={{ backgroundColor: 'blue' }}
                                        >
                                            {defaultTableRowRenderer(props)}
                                        </span>
                                    )}
                                >
                                    {filteredColumnDefs.map(
                                        ({ visible, alias, name }) =>
                                            visible && (
                                                <Column
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        padding: '0px 16px',
                                                        fontSize: '0.845rem',
                                                        height: '50px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        marginTop: '25px'
                                                    }}
                                                    width={100}
                                                    maxWidth={300}
                                                    minWidth={60}
                                                    dataKey={name}
                                                    flexGrow={1}
                                                    flexShrink={1}
                                                    key={name}
                                                    headerStyle={{ textTransform: 'initial', border: 'none' }}
                                                    defaultSortDirection={sortingOrderDirection === 'asc' ? 'ASC' : 'DESC'}
                                                    headerRenderer={() => (
                                                        <StyledTableHeaderCell sortDirection={'asc'} key={name} component="div">
                                                            {columnsWithSorting.includes(name) ? (
                                                                <TableSortLabel
                                                                    tabIndex={-1}
                                                                    active={valueToOrderBy === name}
                                                                    direction={valueToOrderBy === name ? sortingOrderDirection : 'asc'}
                                                                    onClick={() => handleRequestSort(name)}
                                                                >
                                                                    {alias}
                                                                </TableSortLabel>
                                                            ) : (
                                                                alias
                                                            )}
                                                        </StyledTableHeaderCell>
                                                    )}
                                                    cellRenderer={({ columnIndex, rowData }) => {
                                                        const { type } = filteredColumnDefs[columnIndex];

                                                        if (type === columnType.ct_string && rowData[name as keyof Member]) return rowData[name as keyof Member];

                                                        if (!rowData[name as keyof Member]) return '-';
                                                    }}
                                                />
                                            )
                                    )}
                                </StyledVirtualizedTable>
                            )}
                        </AutoSizer>
                    </Paper>
                ) : (
                    <AutoSizer>
                        {({ width, height }) => (
                            <StyledVirtualizedTable
                                tabIndex={-1}
                                width={width}
                                height={height}
                                headerHeight={60}
                                rowHeight={50}
                                rowCount={sortedArr.length}
                                rowGetter={({ index }) => sortedArr[index]}
                                onRowClick={({ rowData, event }) => {
                                    handleClick(event, rowData.MembershipID);
                                }}
                                rowStyle={{
                                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                                }}
                                rowRenderer={(props) => (
                                    <span
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleClick(e, props.rowData.MembershipID);
                                            }
                                        }}
                                        key={props.rowData.MembershipID}
                                        style={{ backgroundColor: 'blue' }}
                                    >
                                        {defaultTableRowRenderer(props)}
                                    </span>
                                )}
                            >
                                {filteredColumnDefs.map(
                                    ({ visible, alias, name }) =>
                                        visible && (
                                            <Column
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    padding: '0px 16px',
                                                    fontSize: '0.845rem',
                                                    height: '50px',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    marginTop: '25px'
                                                }}
                                                width={150}
                                                maxWidth={300}
                                                minWidth={100}
                                                dataKey={name}
                                                flexGrow={1}
                                                flexShrink={1}
                                                key={name}
                                                headerStyle={{ textTransform: 'initial', border: 'none' }}
                                                defaultSortDirection={sortingOrderDirection === 'asc' ? 'ASC' : 'DESC'}
                                                headerRenderer={() => (
                                                    <StyledTableHeaderCell sortDirection={'asc'} key={name} component="div">
                                                        {columnsWithSorting.includes(name) ? (
                                                            <TableSortLabel
                                                                tabIndex={-1}
                                                                active={valueToOrderBy === name}
                                                                direction={valueToOrderBy === name ? sortingOrderDirection : 'asc'}
                                                                onClick={() => handleRequestSort(name)}
                                                            >
                                                                {alias}
                                                            </TableSortLabel>
                                                        ) : (
                                                            alias
                                                        )}
                                                    </StyledTableHeaderCell>
                                                )}
                                                cellRenderer={({ columnIndex, rowData }) => {
                                                    const { type } = filteredColumnDefs[columnIndex];

                                                    if (type === columnType.ct_string && rowData[name as keyof Member]) return rowData[name as keyof Member];

                                                    if (!rowData[name as keyof Member]) return '-';
                                                }}
                                            />
                                        )
                                )}
                            </StyledVirtualizedTable>
                        )}
                    </AutoSizer>
                )}
            </TableContainer>
        </Box>
    );
};

export default MembersPage;
