import { createContext, useContext } from 'react';

export enum LayoutMode {
    lm_standalone,
    lm_embedded
}
export const lms_standalone = 'standalone';
export const lms_embedded = 'embedded';

export const getLayoutMode = (layoutString: string): LayoutMode => {
    switch (layoutString) {
        case lms_standalone: {
            return LayoutMode.lm_standalone;
            break;
        }
        case lms_embedded: {
            return LayoutMode.lm_embedded;
            break;
        }
        default: {
            return LayoutMode.lm_standalone;
            break;
        }
    }
};
export type LayoutBaseType = {
    layout: LayoutMode;
    setLayout: (l: LayoutMode) => void;
};
export const LayoutBase = createContext<LayoutBaseType>({
    layout: LayoutMode.lm_standalone, // set a default value
    setLayout: (l: LayoutMode) => undefined
});
export const useLayoutContext = () => useContext(LayoutBase);

/*
to use :
    
    import { useLayoutContext } from '../contexts/LayoutContext';

    const { layout, setLayout } = useLayoutContext();

*/
