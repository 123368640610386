import React, { useEffect, useState, useRef } from 'react';

// import components
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import API & used types
import { API } from '../api/apiInterface';
import { Organisation } from '../api/apiTypes';
// import react router components
import { useSearchParams } from 'react-router-dom';
// import styles
import { StyledTable, StyledTableCell, StyledTableHeaderCell, StyledTableRow } from '../customisation/theme/styles';
import { ColumnDefs } from '../classes/columns';
// import global contexts
import { useOrganisationsContext } from '../contexts/OrganisationsContext';
import { useOrganisationContext } from '../contexts/OrganisationContext';
import { useSearchQueryContext } from '../contexts/SearchQueryContext';
import { useSearchActiveContext } from '../contexts/SearchActiveContext';
import { useVirtualHistory } from '../classes/virtualHistory';
import { useThemeContext } from '../contexts/ThemeContext';
import { defaultTheme } from '../customisation/theme/defaultStyle';
// import custom text
import OrganisationListHeader from '../customisation/text/OrganisationListHeader';
// import table columns
import { organisationTableColumns } from '../classes/tableColumns';

const OrganisationsPage = () => {
    // use global contexts
    const { searchQuery, setSearchQuery } = useSearchQueryContext(); // &filter=
    const { setSearchActive } = useSearchActiveContext();
    const { organisations } = useOrganisationsContext();
    const { setOrganisation } = useOrganisationContext();
    const { setTheme } = useThemeContext();

    // setup columns
    const viewData = useRef<organisationTableColumns>(new organisationTableColumns());

    viewData.current.generateColumnDefs();

    const [columnDefs] = useState<ColumnDefs>(viewData.current.columns.columns());

    const [organisationMap, setOrganisationMap] = useState<Map<string, Organisation>>(new Map<string, Organisation>());

    const [raToken, setratoken] = useState(''); // &ratoken=

    const [page, setPage] = React.useState(0); // &page=
    const [rowsPerPage, setRowsPerPage] = React.useState(5); // &rowwsperpage=

    // hook for combined URI query string
    const [search, setSearch] = useSearchParams();

    // virtual navigation hooks
    const { vh_goto } = useVirtualHistory();

    const [searchCache, setSearchCache] = useState<Map<string, string>>(new Map<string, string>());

    useEffect(() => {
        // _ns post document max heigth to outer wrapper page
        const body = document.body,
            html = document.documentElement,
            height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const msg = 'Height:' + height;
        window.parent.postMessage(msg, '*');
    });

    // run once
    useEffect(() => {
        // use default theme
        setTheme(defaultTheme);
    }, [setTheme]);

    // run whever search changes
    useEffect(() => {
        const t = search.get('ratoken'); // ratoken = null || string
        const fq = search.get('filter') || ''; // filter query
        const p = Number(search.get('page') || '0'); // page
        const rpp = Number(20); // set at 20, and hide pagination in returned markup

        if (t != null) {
            API.setAuthToken(t).then((auth) => {
                setratoken(t || '');
            });
        }
        setSearchQuery(fq);
        setRowsPerPage(rpp);
        setPage(p);
    }, [search, setSearchQuery]);

    // run whenever organisations changes
    useEffect(() => {
        setSearchActive(true);
        setOrganisation(null);

        if (organisations) {
            setSearchCache(viewData.current.columns.generateSearchCacheGeneric(organisations, 'OrganisationID'));

            const sc = new Map<string, Organisation>();
            organisations.forEach((m: Organisation) => {
                sc.set(m.OrganisationID, m);
            });

            setOrganisationMap(sc);
            return;
        }
    }, [organisations, setOrganisation, setSearchActive]); // nb. allow multiple changes as organisation change > 1

    // event handlers

    const handleClick = (OrganisationID: string) => {
        const o = organisationMap.get(OrganisationID);
        if (o) {
            vh_goto('../../' + o.Slug + '?' + search.toString());
        }
    };

    // calculate filtered data. nb. updated on parameter changes
    const dataFiltered = viewData.current.filterData(searchQuery, organisations, 'OrganisationID', searchCache);

    const rowCount = dataFiltered.length;

    // limit page based on filtered data. nb. also reset page to 0 on render pre-data load
    const pageSafe = rowCount <= 0 || rowsPerPage < 0 ? 0 : Math.min(page, Math.ceil(rowCount / rowsPerPage) - 1);

    return (
        <div>
            <Box sx={{ p: 2 }}>
                <OrganisationListHeader />
            </Box>

            <TableContainer component={Paper}>
                <StyledTable aria-label="simple table">
                    <TableHead>
                        <TableRow key="columnHeaders">
                            {columnDefs.map(
                                (colDef) =>
                                    colDef.visible && (
                                        <StyledTableHeaderCell key={colDef.alias} sx={viewData.current.columnWidth(colDef.name)}>
                                            {colDef.alias}
                                        </StyledTableHeaderCell>
                                    )
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0 ? dataFiltered.slice(pageSafe * rowsPerPage, pageSafe * rowsPerPage + rowsPerPage) : dataFiltered).map((row: Organisation) => (
                            <StyledTableRow
                                hover
                                tabIndex={0}
                                onClick={(event) => handleClick(row.OrganisationID)}
                                key={row.OrganisationID}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleClick(row.OrganisationID);
                                    }
                                }}
                                sx={{
                                    '&:last-child td, &:last-child th': { border: 0 }
                                }}
                            >
                                {columnDefs.map(
                                    (colDef) =>
                                        colDef.visible && (
                                            <StyledTableCell key={row.OrganisationID + '-' + colDef.name} sx={{ p: 1 }}>
                                                {(row as any)[colDef.name]}
                                            </StyledTableCell>
                                        )
                                )}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </TableContainer>
        </div>
    );
};

export default OrganisationsPage;
