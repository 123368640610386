import { Organisation } from '../api/apiTypes';
import { createContext, useContext } from 'react';

export type OrganisationBaseType = {
    organisation: Organisation | null;
    setOrganisation: (b: Organisation | null) => void;
};
export const OrganisationBase = createContext<OrganisationBaseType>({
    organisation: null, // set a default value
    setOrganisation: (base: Organisation | null) => undefined
});
export const useOrganisationContext = () => useContext(OrganisationBase);

/*
to use :
    
    import { useOrganisationContext } from '../contexts/OrganisationContext';

    const { organisation, setOrganisation } = useOrganisationContext();

*/
