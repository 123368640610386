import { Authentication } from '../api/apiTypes';
import { createContext, useContext } from 'react';

export type AuthBaseType = {
    auth: Authentication | null;
    setAuth: (b: Authentication) => void;
};

export const AuthBase = createContext<AuthBaseType>({
    auth: null, // set a default value
    setAuth: (base: Authentication | null) => undefined
});
export const useAuthContext = () => useContext(AuthBase);

/*
to use :
    
    import { useAuthContext } from '../contexts/AuthContext';

    const { auth, setAuth } = useAuthContext();

*/
